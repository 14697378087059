import { MetaTags } from '@/lib/types';
import { PAGE_TITLE_SUFFIX } from '@/lib/constants';

const formatHomePageMetaTags = (pageMetaTags: MetaTags[]): MetaTags[] => {
  return pageMetaTags.map((metaTag) => {
    if (metaTag.tag === 'title' && metaTag.content) {
      return {
        ...metaTag,
        content: metaTag.content.replace(PAGE_TITLE_SUFFIX, '').trim(),
      };
    }
    return metaTag;
  });
};

export { formatHomePageMetaTags };
