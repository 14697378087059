import { isEmpty } from 'lodash';
import dynamic from 'next/dynamic';
import type { FC } from 'react';

import {
  AccordionBlockType,
  BannerBlockType,
  ContentBlockType,
  CtaBlockType,
  CtaGridBlockType,
  FeatureItemBlockType,
  FormBlockType,
  HtmlBlockType,
  ImageBlockType,
  LinkListBlockType,
  NoticeBlockType,
  PetInsuranceBlockType,
  PromoTileBlockType,
  TextBlockType,
  Theme,
  TopTasksBlockType,
  VideoBlockType,
  WidgetBlockType,
} from '@/lib/types';
import { BlockContext, BlockContextType, defaultBlockContext } from '@/lib/contexts/block';
import { captureMessage } from '@/utils/sentry';

const blockMap = {
  accordion_block: dynamic<AccordionBlockType>(() =>
    import('@/components/accordion-block').then((mod) => mod.AccordionBlock)
  ),
  banner_block: dynamic<BannerBlockType>(() => import('@/components/banner-block').then((mod) => mod.BannerBlock)),
  cta_block: dynamic<CtaBlockType>(() => import('@/components/cta-block').then((mod) => mod.CtaBlock)),
  cta_grid_block: dynamic<CtaGridBlockType>(() =>
    import('@/components/cta-grid-block').then((mod) => mod.CtaGridBlock)
  ),
  feature_item_block: dynamic<FeatureItemBlockType>(() =>
    import('@/components/feature-item-block').then((mod) => mod.FeatureItemBlock)
  ),
  feature_contact_us_block: dynamic<FeatureItemBlockType>(() =>
    import('@/components/feature-contact-us').then((mod) => mod.FeatureContactUs)
  ),
  form_block: dynamic<FormBlockType>(() => import('@/components/form-block').then((mod) => mod.FormBlock)),
  html_block: dynamic<HtmlBlockType>(() => import('@/components/html-block').then((mod) => mod.HtmlBlock)),
  image_block: dynamic<ImageBlockType>(() => import('@/components/image-block').then((mod) => mod.ImageBlock)),
  link_list_block: dynamic<LinkListBlockType>(() =>
    import('@/components/link-list-block').then((mod) => mod.LinkListBlock)
  ),
  notice_block: dynamic<NoticeBlockType>(() => import('@/components/notice-block').then((mod) => mod.NoticeBlock)),
  promo_tile_block: dynamic<PromoTileBlockType>(() =>
    import('@/components/promo-tile-block').then((mod) => mod.PromoTileBlock)
  ),
  text_block: dynamic<TextBlockType>(() => import('@/components/text-block').then((mod) => mod.TextBlock)),
  top_tasks_block: dynamic<TopTasksBlockType>(() =>
    import('@/components/top-tasks-block').then((mod) => mod.TopTasksBlock)
  ),
  video_block: dynamic<VideoBlockType>(() => import('@/components/video-block').then((mod) => mod.VideoBlock)),
  widget_block: dynamic<WidgetBlockType>(() => import('@/components/widget-block').then((mod) => mod.WidgetBlock)),
  unknown: null,
  pet_insurance_block: dynamic<PetInsuranceBlockType>(() =>
    import('@/components/pet-insurance-block').then((mod) => mod.PetInsuranceBlock)
  ),
};

const DEFAULT_BLOCK_THEME: Theme = 'Light';

const ContentBlocks: FC<{
  blocks: ContentBlockType[];
  context?: BlockContextType;
}> = ({ blocks, context = defaultBlockContext }) => {
  if (isEmpty(blocks)) {
    return null;
  }

  return (
    <div className="component content col-12">
      <div className="component-content">
        <div className="row">
          {blocks.map((block: ContentBlockType, index) => {
            if (block.type === 'unknown') {
              captureMessage('Unknown block type', { extra: { block } });
              return null;
            }

            const BlockComponent = blockMap[block.type];

            if (!BlockComponent) {
              captureMessage('Unmapped block component', { extra: { block } });
              return null;
            }

            const prevBlock = blocks[index - 1];
            const nextBlock = blocks[index + 1];
            const currentBlockTheme = 'theme' in block ? block.theme : DEFAULT_BLOCK_THEME;
            const prevBlockTheme = prevBlock && 'theme' in prevBlock ? prevBlock.theme : DEFAULT_BLOCK_THEME;
            const nextBlockTheme = nextBlock && 'theme' in nextBlock ? nextBlock.theme : DEFAULT_BLOCK_THEME;
            const isSameThemeAsPrevious = Boolean(prevBlock && prevBlockTheme === currentBlockTheme);
            const isSameThemeAsNext = Boolean(nextBlock && nextBlockTheme === currentBlockTheme);

            return (
              <BlockContext.Provider
                key={block.id}
                value={{
                  ...context,
                  isSameThemeAsPrevious,
                  isSameThemeAsNext,
                }}
              >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <BlockComponent {...block} />
              </BlockContext.Provider>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { ContentBlocks };
