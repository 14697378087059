import { GetStaticProps } from 'next';
import { isEmpty } from 'lodash';
import type { ReactElement } from 'react';

import { ContentBlocks } from '@/components/content-blocks';
import { formatHomePageMetaTags } from '@/utils/format-home-page-meta-tags';
import { getHomePage } from '@/lib/datocms/api';
import { HomePage as HomePageType } from '@/lib/types';
import { Layout } from '@/components/layout';
import { PAGE_REVALIDATE_INTERVAL } from '@/lib/constants';

type PageProps = { page: HomePageType };

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const data = await getHomePage();

  if (!data || !data.homePage) {
    return {
      notFound: true,
    };
  }

  return {
    props: { page: data.homePage },
    revalidate: PAGE_REVALIDATE_INTERVAL,
  };
};

const HomePage = ({ page }: PageProps): ReactElement => {
  const homePageMetaTags = formatHomePageMetaTags(page.metaTags);
  return (
    <Layout metaTags={homePageMetaTags} paths={[]}>
      {!isEmpty(page.content) && <ContentBlocks blocks={page.content} />}
    </Layout>
  );
};

export default HomePage;
